<script lang="ts" setup>
  import {ref, onMounted} from 'vue';
  import {useHomeBannersStore} from '~/stores';

    const props = withDefaults(defineProps<{
      /** Custom description */
      src: string
      current: boolean
      alt: string
      aspectRatio?:string;
      lazyLoad?: boolean;
    }>(), {aspectRatio: '', lazyLoad: false});

    const img = ref();

    const emit = defineEmits(['load']);

    const bannersStore = useHomeBannersStore();

    onMounted(() => {
      if (!props.lazyLoad || !img.value) {
        return;
      }
      const element = img.value;

      nextTick(() => {
        if (!element || !element.complete) {
          return;
        }
        emit('load');
      });
    });

</script>

<template>
    <img
        v-if="lazyLoad"
        ref="img"
        :class="current ? 'cp-banner-image__current' : 'cp-banner-image__preview'"
        :src="src"
        :alt="alt"
        :loading="'eager'"
        :fetchpriority="'high'"
        :style="aspectRatio"
        @load="$emit('load')"
    >
    <img
        v-else-if="bannersStore.firstImageLoaded || !lazyLoad"
        :class="current ? 'cp-banner-image__current' : 'cp-banner-image__preview'"
        :src="src"
        :alt="alt"
        :loading="'lazy'"
        :fetchpriority="'low'"
        :style="aspectRatio"
    >
    <v-lazy v-else transition="fade-transition">
        <img
            :class="current ? 'cp-banner-image__current' : 'cp-banner-image__preview'"
            :src="src"
            :alt="alt"
            :loading="'lazy'"
            :fetchpriority="'low'"
            :style="aspectRatio"
        >
    </v-lazy>
</template>

<style scoped lang="scss">

    .cp-banner-image {
      display: flex;
      flex-grow: 1;
      width: 100%;
      &__current {
          border-radius: 8px;
          object-fit: cover;
          object-position: left;
          width: 100%;
      }
      &__preview {
          opacity: 80%;
          filter: blur(4dp);
          -webkit-filter: blur(4dp);
          border-radius: 8px;
          object-fit: cover;
          object-position: left;
      }
    }
</style>
