<script setup lang="ts">
    import {Autoplay as SwiperAutoplay} from 'swiper/modules';
    import {computed, toRefs} from 'vue';
    import type {IBannerPlace} from '~/types';
    import CpBanner from '~/components/cp/CpBanner/CpBanner.vue';
    import {shuffleArray} from '~/utils/shuffleArray';

    const props = defineProps<{
      place: IBannerPlace
      applyAspectRatio?: boolean
      priority?: boolean
      aspectRatio?:string;
    }>();
    const {banners: images, placeType} = toRefs(props.place);
    const banners = computed(() => {
      if (placeType.value === 'static') {
        return images.value.slice(0, 1);
      }
      // Let's randomize the order of the banners to make it more interesting IT-19954
      if (placeType.value === 'stackable') {
        const shuffleImages = shuffleArray(images.value);
        return shuffleImages.length === 2 ? [...shuffleImages, ...shuffleImages] : shuffleImages;
      }
      // Swiper behavior is affected when there are 2 CpBanners due loop
      return images.value.length === 2 ? [...images.value, ...images.value] : images.value;
    });

</script>

<template>
    <div v-if="banners && banners.length" class="cp-slideshow">
        <div v-if="banners.length === 1" class="one__slide">
            <CpBanner
                current
                :link="banners[0].targetUrl"
                :src="banners[0].pictureUrl"
                :alt="banners[0].name"
                :apply-aspect-ratio="applyAspectRatio"
                :index="priority ? 0 : undefined"
                :aspect-ratio="aspectRatio"
                :priority="priority"
            />
        </div>
        <Swiper
            v-else
            :modules="[SwiperAutoplay]"
            :slides-per-view="'auto'"
            :centered-slides="true"
            :space-between="8"
            :autoplay="{
                delay: 6000
            }"
            :speed="500"
            :loop="banners.length > 1"
            :css-mode="true"
        >
            <SwiperSlide v-for="(banner, bIndex) in banners" :key="banner.name" :style="{ width: 'calc(100% - 32px)' }">
                <CpBanner
                    current
                    :link="banner.targetUrl"
                    :src="banner.pictureUrl"
                    :alt="banner.name"
                    :apply-aspect-ratio="applyAspectRatio"
                    :lazy-load="priority ? bIndex === 0 : false"
                    :aspect-ratio="aspectRatio"
                    :priority="priority"
                />
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<style scoped lang="scss">
  .one__slide {
    margin: 0 auto;
    width: calc(100% - 32px);
  }
  .cp-slideshow {
    margin: $cp-gutter 0 0;
    .swiper-slide-next, .swiper-slide-prev {
      transition: transform 0.5s;
      filter: blur(1px);
      transform: scaleY(0.8);
    }
    .swiper-slide-active {
      transition: transform 0.5s;
    }
  }
</style>
