<script lang="ts" setup>
  import CpBannerImage from './CpBannerImage.vue';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';
  import {useRouterStore} from '~/stores';
  import {useHomeBannersStore} from '~/stores/homeBanners';

    withDefaults(defineProps<{
      /** Custom description */
      src: string
      current: boolean
      link?: string | null
      alt: string
      applyAspectRatio?: boolean
      priority?: boolean
      aspectRatio?:string;
      lazyLoad?: boolean;
      imageLoaded?: boolean
    }>(), {aspectRatio: ''});

    const bannersStore = useHomeBannersStore();

</script>

<template>
    <CpNuxtLink v-if="link" :to="link" @click="useRouterStore().unknownRoute = true">
        <div class="cp-banner" :style="aspectRatio">
            <CpBannerImage
                :src="src"
                :current="current"
                :alt="alt"
                :aspect-ratio="aspectRatio"
                :lazy-load="lazyLoad"
                @load="bannersStore.setImageLoaded()"
            />
        </div>
    </CpNuxtLink>
    <div v-else class="cp-banner" :style="aspectRatio">
        <CpBannerImage
            :src="src"
            :current="current"
            :alt="alt"
            :aspect-ratio="aspectRatio"
            :lazy-load="lazyLoad"
            @load="bannersStore.setImageLoaded()"
        />
    </div>
</template>

<style scoped lang="scss">
  .cp-banner {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }
</style>
